<template>
  <div>
    <div style="position:relative;" v-if="getResult && !subscribed">
      <div class="d-flex flex-1 blured-div"> 
        <div class="wrapper">
          <div class="text-center">
            <h1 class="d-sm-none d-block">Unlock Niches with an Upgrade</h1>
            <h1 style="font-size:3rem" class="d-none d-sm-block">Unlock Niches with an Upgrade</h1>
            <h4>When you upgrade to Pro, you will be able to access keyword search, niches, and more tools from AppLead.</h4>
          </div>
          <b-button style="font-size:2rem" variant="outline-success" to="/pages/plan-selection">
            Upgrade Now
          </b-button>
        </div>
      </div>
      <div style="filter: blur(1px);">
        <b-table striped hover responsive :items="items" :fields="fields" @row-clicked="redirectToPage">
        <template #cell(name)="data">
          <div class="d-flex">
            <img :src="data.item.icon" class="mr-2" width="80" />
            <div>
              <h6 style="min-width:150px;">
                {{ data.value }}
              </h6>
              <p>
                {{ data.item.description }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(updated_at)="data">
          {{ formateDate(data.value) }}
        </template>
      </b-table>
      </div>
      <!-- <PlanSelectionVue /> -->
    </div>
    <div v-if="getResult && subscribed">
      <b-table striped hover responsive :items="items" :fields="fields" @row-clicked="redirectToPage">
        <template #cell(name)="data">
          <div class="d-flex">
            <img :src="data.item.icon" class="mr-2" width="80" />
            <div style="min-width:150px;">
              <h6>
                {{ data.value }}
              </h6>
              <p>
                {{ data.item.description }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(updated_at)="data">
          {{ formateDate(data.value) }}
        </template>
      </b-table>
    </div>
    <div v-if="!getResult">
      <LoaderVue />
    </div>
  </div>
</template>

<script>
import axios from '@axios';
import { BTable,BButton } from 'bootstrap-vue';
import LoaderVue from '@/components/Loader/Loader.vue';
import PlanSelectionVue from '../subscription/PlanSelection.vue';
export default {
  components: {
    BTable,
    PlanSelectionVue,
    LoaderVue,
    BButton
  },
  data() {
    return {
      getResult : null,
      subscribed : null,
      items: [],
      fields: [
        {
          key: "name",
          label: "name",
        },
        {
          key: "apps_count",
          label: "apps",
          class: "text-center"
        },
        {
          key: "updated_at",
          label: "last update",
          class: "text-center"
        },
      ],
    }
  },
  mounted() {
    
    this.checkUserPlan();
    this.getNiches();
  },
  methods: {
    checkUserPlan() {
      axios.post('/checkUserSubscription').then(({ data }) => {
          this.getResult = true;
          this.subscribed = true;
      }).catch((err) => {
        if(err.status === 403){
          this.getResult = true;
          this.subscribed = false;
        }
      });
    },
    getNiches() {
      axios.get('/niches').then(({ data }) => {
        const { niches } = data;
        const lastItem = niches[niches.length - 1];
        niches.pop();
        niches.splice(1, 0, lastItem);
        this.items = niches;
      }).catch(console.error)
    },
    formateDate(a) {
      const date = new Date(a);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const formattedDateStr = `${year}-${month}-${day} ${hour}:${minute}`;
      return formattedDateStr;
    },
    redirectToPage(item) {
      switch (item.id) {
        case 1:
          this.$router.push({ name: 'niche-manager-removed-apps' });
          break;
        case 2:
          this.$router.push({ name: 'niche-manager-guides' });
          break;
        case 3:
          this.$router.push({ name: 'niche-manager-coloring' });
          break;
        case 4:
          this.$router.push({ name: 'niche-manager-wallpaper' });
          break;
        case 5:
          this.$router.push({ name: 'niche-manager-fakecalls' });
          break;
        case 6:
          this.$router.push({ name: 'niche-manager-vpn' });
          break;
        case 7:
          this.$router.push({ name: 'niche-manager-10-k' });
          break;

      }
    }
  }
}
</script>

<style scoped>
.blured-div {
  position: absolute;
  background-color: black;
  opacity: 0.8;
  z-index: 300;
  width: 100% !important;
  height: 100% !important;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>